import React, { useContext, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { BorderBottom, ExpandMore } from '@mui/icons-material';
import { Text } from '../elements/MI-elements';
import STRINGS from '../assets/Strings';
import TheContext from '../Storage/theContext';
import { summarizeShifts } from './../RES/shifts';
import LottieAnimation from '../assets/animation/animation';

const Summary = ({ totalHours, body }) => {
    const { Language } = useContext(TheContext);
    const [SummaryData, setSummaryData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getSummaryData = async () => {
        setLoading(true);
        const respond = await summarizeShifts(body)
        .finally(() => setLoading(false));
        setSummaryData(respond?.data || []);
    };

    const renderCustomer = () => {

        const renderProjects = (projects) => {
            return (
                Object.entries(projects).map(([key, value]) => (
                    <Text key={key} style={styles.projectItem}>
                        - {value.projectName}: {value.total}
                    </Text>
                ))
            );
        };

        return (
            Array.isArray(SummaryData) && SummaryData.map((customer) => (
                <div key={customer.id} style={styles.customerContainer}>
                    <Text style={styles.customerName}>
                        {customer.name}: {customer.total}
                    </Text>
                    <div style={styles.projectContainer}>
                        {renderProjects(customer?.project || {})}
                    </div>
                </div>
            ))
        );
    };

    useEffect(() => {
        getSummaryData();
    }, [totalHours]);

    return (
        <div style={styles.Summary}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Text style={styles.summaryTxt}>
                        {STRINGS.summary[Language]}
                        {loading && ' ...'}
                    </Text>
                    <Text style={styles.total}>
                        🕛 {STRINGS.TotalHours[Language]}: {totalHours}
                    </Text>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={styles.summaryRender}>
                        {loading && <LottieAnimation data={"loading"} style={{width:'100px',height:'100px',alignSelf:'center'}}/>}
                        {loading || renderCustomer(SummaryData)}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

const styles = {
    Summary: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingTop:'10px',
        maxWidth: '700px',
        alignSelf: 'center',
        paddingInline: "5%"
    },
    summaryTxt: {
        display: 'flex',
        width: '40%',
    },
    total: {
        display: 'flex',
        width: '50%',
        justifyContent: 'flex-end',
    },
    summaryRender: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    customerContainer: {
        marginBottom: '5px',
        borderBottom: '1px solid #ccc',
        padding: '10px',
    },
    customerName: {
        marginBottom: '5px',
        textAlign: 'left',
        fontSize: '17px',
    },
    projectContainer: {
        marginLeft: '5px',
    },
    projectItem: {
        textAlign: 'left',
    },
};

export default Summary;
