import React, { useContext, useEffect, useState } from 'react';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useLoaderData } from 'react-router-dom';
import { JsonToExcelButton } from '../../../assets/LocalFunctions/JsonToExcel';
import BoxOfArrShiftsInDay from '../../../components/ShiftComponent/BoxOfArrShiftsInDay';
import { Page, Text, Title } from '../../../elements/MI-elements';
import TheSelectBox from '../../../components/TheSelectBox';
import { dayShift } from '../../../assets/globalValues';
import TheContext from '../../../Storage/theContext';
import { toast } from 'react-toastify';
import STRINGS from '../../../assets/Strings';
import { getUserFromStorage } from '../../../Storage/restoreData';
import { getWorkingHourMonthShift } from '../../../RES/shifts';
import Summary from '../../../components/SummaryParagraph';

const SelectedSectionTemplate = (user, date) => {
    return {
        customers: [],
        projects: [],
        shiftsDates: user ? [{ name: date, _id: date }] : [],
        users: user ? [{ name: user.name, _id: user._id }] : [],
    }
}
const SelectedSectionLabel = (Language) => {
    return {
        customers: STRINGS.customers[Language],
        projects: STRINGS.projects[Language],
        shiftsDates: STRINGS.SelectDate[Language],
        users: STRINGS.user[Language]
    }
}
const SelectedSectionTemplateX = {
    users: [],
    projects: [],
    customers: [],
    shiftsDates: [],
}

const AllWorkingHour = () => {
    const { DateStats, Language } = useContext(TheContext);
    const { date } = DateStats.keyMonth;
    let user = getUserFromStorage()
    let isAdmin = user?._isAdmin;
    const LoaderData = useLoaderData();
    const SelectBoxData = LoaderData?.Data || SelectedSectionTemplateX;
    const [selectedSection2, setSelectedSection2] = useState(SelectedSectionTemplate(user, date));//SelectedSectionTemplate(user, date)
    const [filteredShift, setFilteredShift] = useState({});
    const [TotalHours, setTotalHours] = useState(0);
    const [submit, setSubmit] = useState(true);

    const RenderShifts = () => {
        return (
            Object.entries(filteredShift).map(([day, shiftArray], i) => (
                shiftArray.length ? <TableRow key={day}>
                    <TableCell>
                        <Text>
                            {day}
                        </Text>
                    </TableCell>
                    <TableCell>
                        <BoxOfArrShiftsInDay key={i} day={day} ArrayShiftInDay={shiftArray} AdminReq={true} />
                    </TableCell>
                </TableRow> : null
            ))
        )
    }

    const RenderTableSelectHead = () => {
        const toDisabled = (type) => {
            if (type === "users" && !isAdmin) {
                return true;
            }
        }
        return (
            <div style={styles.TableSelectHead}>
                {/* {console.log(SelectBoxData)} */}
                {Object.entries(SelectBoxData).map(([type, options], i) =>
                    <TheSelectBox
                        key={i + [type]}
                        Data={selectedSection2}
                        setData={setSelectedSection2}
                        type={type}
                        options={options}
                        fullWidth={true}
                        disabled={toDisabled(type)}
                        label={SelectedSectionLabel(Language)[type]}
                    />
                )}
            </div>
        )
    }

    const RenderTableHead = () => (
        <TableRow>
            {dayShift.map((name, i) =>
                <TableCell sx={styles.tableHed} key={i}>{name[Language]}</TableCell>
            )}
        </TableRow>
    )

    const getIds = (values) => {
        return values?.map((value) => value?._id)
    }

    const createFilterBody = () => {
        const body = {}
        const KeysBody = { users: "worker", customers: "customer", projects: "project", shiftsDates: "dateKey" }
        Object.entries(selectedSection2).map(([key, values], i) => {
            if (values?.length) {
                body[KeysBody[key]] = { $in: getIds(values) }
            }
            return null
        })
        return body
    }

    const DownloadExcelButton = () => {
        let IsDark = JSON.parse(localStorage.getItem('Dark'))
        return (
            <div >
                <Button
                    variant={IsDark ? 'text' : 'contained'}
                    color='success'
                    onClick={() => JsonToExcelButton(filteredShift, 0, selectedSection2)}
                >
                    <img src={require("./excel.png")} alt="excel" style={{ height: 32, width: 32, margin: 5 }} />
                    <>Download As Excel</>
                </Button>
            </div>
        )
    }

    const getUserShift = async () => {
        const filterShiftBody = createFilterBody();
        const userShift2 = await getWorkingHourMonthShift(filterShiftBody);
        setFilteredShift(userShift2?.data || {})
        setTotalHours(userShift2?.total || 0)
    }

    const submitFilterShift = () => {
        toast.promise(
            getUserShift(),
            {
                pending: STRINGS.Loading[Language],
                success: `${STRINGS.success[Language]}✅`,
                error: `${STRINGS.failed[Language]}❌`
            }
        )
        setSubmit(true)
    }

    useEffect(() => {
        submitFilterShift()
        // console.log("selectedSection2 ✅✅",selectedSection2);
    }, []);

    useEffect(() => {
        setSubmit(false)
    }, [selectedSection2,]);

    return (
        <Page>
            <div style={{ marginTop: "10px" }}>
                <DownloadExcelButton />
            </div>

            <Title>{STRINGS.WorkingHour[Language]}</Title>

            <div style={{ width: "100dvw", }}>
                <RenderTableSelectHead />
                <div style={styles.submitBtn}>
                    <Button
                        sx={styles.filter}
                        variant={'contained'}
                        disabled={submit}
                        onClick={submitFilterShift}>
                        {STRINGS.Filter?.[Language]}
                    </Button>
                </div>
                <div style={styles.Summary}>
                    <Summary
                        body={createFilterBody()}
                        totalHours={TotalHours.toFixed(2)}
                    />
                </div>
            </div>

            <div style={{ paddingInline: "5%" }}>
                <Table>
                    <TableHead>
                        <RenderTableHead />
                    </TableHead>

                    <TableBody>
                        <RenderShifts />
                    </TableBody>

                </Table>
            </div>
        </Page>
    )
}

export default AllWorkingHour;

const styles = {
    tableHed: { backgroundColor: '#1999', fontWeight: 'bold' },
    TableSelectHead: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flex: 1,
        paddingInline: '5%',
        flexWrap: (window.innerWidth <= 600) ? 'wrap' : 'nowrap',
    },
    submitBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingInline: "10%"
    },
    filter: {
        fontWeight: 'bold',
        fontSize: '16px'
    },
    Summary: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    }
}