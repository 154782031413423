import { fetchApi, stringify } from "./API";

export const getWorkingHourMonthShift = async (body) => {
    console.log('~getWorkingHourMonthShift~\n', "~load~");
    let groupId = localStorage.getItem("group-id");
    let query = `?group-id=${groupId}`;
    const route = `/getWorkingHourMonthShift${query}`;
    return await fetchApi(route, 'POST', stringify(body))
}

export const getShiftDuration = async (body) => {
    console.log('~getShiftDuration~\n', "~load~");
    let groupId = localStorage.getItem("group-id");
    let query = `?group-id=${groupId}`;
    const route = `/getShiftDuration${query}`;
    return await fetchApi(route, 'POST', stringify(body))
}

export const summarizeShifts = async (body) => {
    console.log('~summarizeShifts~\n', "~load~");
    let groupId = localStorage.getItem("group-id");
    let query = `?group-id=${groupId}`;
    const route = `/summarizeShifts${query}`;
    return await fetchApi(route, 'POST', stringify(body))
}